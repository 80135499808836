import React from "react";

const ModalOne = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "img/service/1.jpg"
              })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Extended Reality</h3>
        <p>
          Extended Reality (XR) is a general term for all technologies
          involving virtuality and reality, including but not limited
          to Virtual Reality (VR), Augmented Reality (AR), Mixed
          Reality (MR), etc.
        </p>
        <p>
          As the very first XR technology - VR is becoming more and more mature.
          We can see VR products with a resonable price have alrady been on market
          for years, and many people have purchased or tried them. However, there
          is still not an obvious demand growth. Most people just use it out of
          curiosity. Therefore, it is necessary to explore and establish the true
          value behind VR before it can really be widespread like mobile phones.
        </p>
        <p>
          Other XR technologies, e.g. AR, are far from mature. Either the exorbitant
          price prevents consumers from buying, or the bulky machine makes it
          uncomfortable for the user to wear, or the field of view is too small
          to support normal use. There are still technical obstacles that must
          be solved.
        </p>
        <p>
          I believe that Extended Reality is going to become the next-generation world-changing
          technology, and my dream is to do my contribution in this field.
        </p>
      </div>
    </div>
  );
};

export default ModalOne;
