import React from "react";
import { FiBookOpen } from "react-icons/fi";

const activitiesContent = [
  {
    year: "December 2021 – Present",
    degree: "IEEE & Eta Kappa Nu (HKN)",
    institute: "Member",
    details: `Hosted review sessions and peer tutoring for university students who are interested in Electrical and Computer Engineering`,
  },
  {
    year: "October 2017",
    degree: "Royal Society of Chemistry - Chemistry Challenge",
    institute: "Contestant",
    details: `Awarded Silver Medal in China (National 25%)`,
  },
  {
    year: "July 2017",
    degree: "International Youth Innovation and Design Competition",
    institute: "Team Leader and Designer",
    details: `Awarded Second Place (International 2%)`,
  },
];

const Activities = () => {
  return (
    <ul>
      {activitiesContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <FiBookOpen alt="icon" />
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Activities;
