import React from "react";

const ModalTwo = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "img/service/2.jpg"
              })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Internet of Things</h3>
        <p>
          Internet of Things (IoT) broadens the ordinary concept of Internet.
          Not only computing dvices - such as personal computers and mobile phones -
          are connected, physical objects - e.g. desks - can also be integrated
          with sensors, processing ability, software, and other technologies, and thus
          should also be connected through Internet.
        </p>
        <p>
          If you have read the Extended Reality page, then you would know that
          I want to explore the true value behind XR technologies. Even though
          they are just immature thoughts in my mind, I do consider XR and IoT to
          be complementary technologies, since XR is a better way to interact with
          possibly many IoT devices surrounding us in the future.
        </p>
        <p>
          Therefore, looking for potential relationships between the two fields is
          also an interesting topic to me.
        </p>
      </div>
    </div>
  );
};

export default ModalTwo;
