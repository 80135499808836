import React from "react";

const Map = () => {
  return (
    <>
      <div className="google-map">
        <iframe
          className="embed-responsive-item"
          title="my location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3051.4262686823545!2d-88.2364210486357!3d40.110502979300776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880cd73863860eb3%3A0xefdd552f4ab450d0!2s308%20E%20Green%20St%2C%20Champaign%2C%20IL%2061820!5e0!3m2!1sen!2sus!4v1641539840156!5m2!1sen!2sus"
        ></iframe>
      </div>
      {/* End google-map */}
    </>
  );
};

export default Map;
