import React from "react";
import { FiBriefcase } from "react-icons/fi";

const experienceContent = [
  {
    year: "March 2020 – Present",
    position: "Initiator and Developer",
    compnayName: "Fundot",
    details: `Initiated the GitHub Project: The Fundot Programming Language`,
  },
  {
    year: "June 2021 – Present",
    position: "Project Developer",
    compnayName: "ILLIXR",
    details: `Modularized the installation of ILLIXR runtime and made it cross-platform`,
  },
  {
    year: "March 2020 – Present",
    position: "Game Developer",
    compnayName: "IoT Project",
    details: `Found suitable game resources and assets from the Unity Asset Store`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <FiBriefcase alt="icon" />
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
